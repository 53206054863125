const moneyTypeOpts = [
  {
    label: "美元",
    value: 0,
    code: "USD",
    symbol: "$",
  },
  {
    label: "欧元",
    value: 1,
    code: "EUR",
    symbol: "€",
  },
  {
    label: "英镑",
    value: 2,
    code: "GBP",
    symbol: "£",
  },
  {
    label: "日元",
    value: 3,
    code: "JPY",
    symbol: "￥",
  },
  {
    label: "人民币",
    value: 4,
    code: "CNY",
    symbol: "￥",
  },
  {
    label: "加拿大元",
    value: 5,
    code: "CAD",
    symbol: "$",
  },
  {
    label: "澳大利亚元",
    value: 6,
    code: "AUD",
    symbol: "$",
  },
  {
    label: "瑞士法郎",
    value: 7,
    code: "CHF",
    symbol: "Fr",
  },
  {
    label: "新西兰元",
    value: 8,
    code: "NZD",
    symbol: "$",
  },
  {
    label: "韩元",
    value: 9,
    code: "KRW",
    symbol: "₩",
  },
  {
    label: "印度卢比",
    value: 10,
    code: "INR",
    symbol: "₹",
  },
  {
    label: "巴西雷亚尔",
    value: 11,
    code: "BRL",
    symbol: "R$",
  },
  {
    label: "南非兰特",
    value: 12,
    code: "ZAR",
    symbol: "R",
  },
  {
    label: "西班牙比塞塔",
    value: 13,
    code: "ESP",
    symbol: "₧",
  },
  {
    label: "泰国铢",
    value: 14,
    code: "THB",
    symbol: "฿",
  },
  {
    label: "瑞典克朗",
    value: 15,
    code: "SEK",
    symbol: "kr",
  },
  {
    label: "新加坡元",
    value: 16,
    code: "SGD",
    symbol: "$",
  },
  {
    label: "阿联酋迪拉姆",
    value: 17,
    code: "AED",
    symbol: "د.إ",
  },
  {
    label: "墨西哥比索",
    value: 18,
    code: "MXN",
    symbol: "$",
  },
  {
    label: "俄罗斯卢布",
    value: 19,
    code: "RUB",
    symbol: "₽",
  },
  {
    label: "沙特里亚尔",
    value: 20,
    code: "SAR",
    symbol: "ر.س",
  },
  {
    label: "马来西亚林吉特",
    value: 21,
    code: "MYR",
    symbol: "RM",
  },
  {
    label: "港元",
    value: 22,
    code: "HKD",
    symbol: "HK$",
  },
  {
    label: "柬埔寨瑞尔",
    value: 23,
    code: "KHR",
    symbol: "៛",
  },
  {
    label: "菲律宾比索",
    value: 24,
    code: "PHP",
    symbol: "₱",
  },
];
// 全局过滤器
export default function install(Vue) {
  // 根据value查找对应的货币符号
  Vue.filter("getMoneySymbol", (val) => {
    let symbol = "";
    moneyTypeOpts.forEach((item) => {
      if (item.value == val) {
        symbol = item.symbol;
      }
    });
    return symbol;
  });
}
