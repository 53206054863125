<template>
  <div class="nav-header">
    <div class="header-left">
      <span>{{ pageName }}</span>
    </div>
    <div class="header-right">
      <el-popover
        placement="bottom"
        width="220"
        trigger="hover"
        popper-class="user-popper"
      >
        <div class="logout" @click="logout">
          <span>Log out</span>
        </div>
        <div slot="reference" class="user-info">
          <el-image :src="getUserAvatar()" :lazy="true" fit="cover">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <span>{{ user_info.name }}</span>
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    pageName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      userInfo: {
        name: "",
        avatar: "",
      },
      defaultAvatars: [
        require("@/assets/image/account/avatar1.png"),
        require("@/assets/image/account/avatar2.png"),
        require("@/assets/image/account/avatar3.png"),
        require("@/assets/image/account/avatar4.png"),
        require("@/assets/image/account/avatar5.png"),
        require("@/assets/image/account/avatar6.png"),
      ],
      userAvatar: "",
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  created() {},
  methods: {
    getUserAvatar() {
      // 如果用户有上传头像则使用用户头像，否则随机选择一个默认头像
      return this.user_info.avatar || this.getRandomAvatar();
    },
    // 随机选择一个默认头像
    getRandomAvatar() {
      const randomIndex = Math.floor(
        Math.random() * this.defaultAvatars.length
      );
      return this.defaultAvatars[randomIndex];
    },
    // 退出
    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  .header-left {
    font-family: Helvetica, Helvetica;
    font-weight: bold;
    font-size: 24px;
    color: #333333;
  }
  .header-right {
    width: 220px;
    height: 48px;
    background: #ffffff;
    border-radius: 12px;
    cursor: pointer;
    ::v-deep .user-info {
      display: flex;
      align-items: center;
      height: 48px;
      padding-left: 12px;
      .el-image {
        width: 32px;
        height: 32px;
        border-radius: 8px;
        img {
          width: 32px;
          height: 32px;
        }
        .image-slot {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      span {
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        padding-left: 12px;
      }
    }
  }
}
</style>
<style lang="scss">
.user-popper {
  padding: 12px 0 !important;
  border-radius: 8px !important;
  .logout {
    text-align: center;
    cursor: pointer;
  }
}
</style>