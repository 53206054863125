export default {
  menu: {
    affiliate_center: '分销中心',
    my_distribution: '我的分销',
    task_center: '任务广场',
    my_task: '我的任务',
    unaffirm_list: '待确认列表',
    affirm_list: '已确认列表',
    account_manage: '账号管理',
    my_account: '我的账号'
  },
  place: {
    my_discount_code: '我的分销折扣码',
    income: '分销收益',
    select_product: '商品选择',
    search: '查 询',
    create_time: '创建时间',
    product: '商品名称',
    distribution_links: '分销链接',
    discount_code: '折扣码',
    commission_type: '佣金类型',
    orders: '订单数',
    operation: '操作',
    details: '详情',
    more: '更多',
    delete: '删除',
    number_of_products: '分销商品数量',
    number_of_orders: '分销订单数量',
    order_amount: '订单金额',
    commission: '佣金',
    order_status: '订单状态',
    payment_status: '付款状态',
    actual_payment: '实际付款',
    distribution_status: '分销状态'
  },
  task_center: {
    slog:
      '仔细查看任务要求，符合要求的可以免费获得这些产品，你只需要为这个产品拍摄一段视频发在你的社交媒体账号上。',
    select: '筛选',
    target_country: '申请国家',
    free_product: '免费产品',
    prodect_value: '货值',
    budget: '预算',
    app_promotion: 'APP推广',
    web_promotion: '网站推广',
    applied_number: '红人申请'
  },
  list: {
    release_time: '发布时间',
    contact_name: '联系人',
    area: '推广地区',
    price_min: '最小金额',
    price_max: '最大金额',
    is_pass: '是否过期',
    is_accept: '是否接受',
    start_date: '开始日期',
    end_date: '结束日期',
    true: '是',
    false: '否',
    input: '请输入',
    search: '查询',
    reset: '重置',
    agree: '同意',
    disagree: '拒绝',
    more: '更多',
    validity: '有效期',
    task_intro: '任务简介',
    task_type: '任务类型',
    blog_type: '红人类型',
    blog_num: '红人数量',
    blog_fans: '粉丝数区间'
  },
  task_detail: {
    task_name: '任务名',
    create_time: '创建日期',
    issuer: '发布人',
    confirm: '接受合作',
    cancel: '拒绝合作',
    tip: '提示',
    cancel_tip: '注意：多次取消将不能接受别的任务',
    confirmation: '确定',
    cancel_2: '取消',
    accept: '接受',
    reject: '拒绝',
    reward: '支付报酬',
    stage: '进度',
    linkup: '沟通中',
    feat: '合作中',
    online: '合作上线',
    finish: '已完成',
    basic_info: '基本信息',
    blog_type: '需要的红人类型',
    blog_num: '需要的红人数',
    pay_reward: '愿意支付的报酬',
    blog_video: '每人视频数',
    explain: '说明',
    pro_info: '产品信息',
    pro_name: '产品名称',
    pro_link: '产品链接',
    task_link: '任务链接',
    platform: '平台',
    classify: '类型',
    video_link: '你的视频链接',
    operation: '操作',
    submit: '提交',
    shipping_address: '收货地址',
    express: '产品邮寄',
    consignee: '收件人',
    contact_number: '联系方式',
    mailing_address: '邮寄地址',
    save: '保存',
    logistics_info: '物流信息',
    express_company: '承运商',
    tracking_number: '物流单号',
    logistics_track: '物流轨迹',
    check_details: '查看详情',
    collection_Info: '收款信息',
    payment: '支付方式',
    payee: '收款人',
    bank: '银行名称',
    payee_account: '收款卡号',
    evaluation: '评价',
    service_level: '您觉得这次合作怎么样?',
    excellent: '非常棒',
    great: '还可以',
    good: '一般',
    chat: '聊天',
    send: '发送',
    cancel_cooperation: '取消合作',
    is_cancel_cooperation: '是否取消合作',
    cancel_reason: '取消原因',
    cancel_cooperation_tip: '注:多次取消将不能接受新的任务',
    task_status1: '已接受，待对方确认',
    task_status3: '双方已合作',
    task_status4: '对方取消合作',
    get_more: '沟通更多细节',
    paypal: 'Paypal',
    bank_transfer: '银行转账',
    product: '实物',
    video_type1: '独立视频',
    video_type2: '植入视频',
    video_type3: '其他'
  },
  accout: {
    age: '年龄',
    man: '男',
    woman: '女',
    birth: '出生日期',
    area: '所在地区',
    edit: '编辑',
    add: '添加',
    authorization: '授权',
    EVPI: '完善信息',
    basicInfo: '基础信息',
    upload: '上传',
    name: '姓名',
    country: '国家',
    ADtype: '可接受的广告类型',
    game: '游戏',
    ARPG: '动作冒险游戏',
    ACG: '休闲游戏',
    RPG: '角色扮演游戏',
    ETC: '其他游戏',
    eBusiness: '电子商务',
    art: '艺术与收藏品',
    car: '汽车',
    toys: '婴儿和玩具',
    beauty: '美容与个人护理',
    clothing: '服装、鞋履和珠宝',
    electronics: '电子产品及配件',
    foot: '食品和厨房',
    home: '家居用品',
    pet: '宠物用品',
    sport: '运动的',
    other: '其他',
    music: '音乐',
    teach: '教育',
    science: '科学技术',
    rate: '不同类型视频的报价',
    dedicatedVideo: '独立视频',
    integratedVideo: '植入视频',
    prerollAd: '片头植入视频',
    middleAd: '片中植入视频',
    endAd: '片尾植入视频',
    receipt_information: '收款信息',
    payment_method_no: '收款方式',
    collection_name: '收款人名称',
    bank_name: '银行名称',
    card_number: '银行卡号',
    contact_type: '联系方式(请输入正确的信息)',
    email: '邮箱地址',
    save: '保存',
    blog_name: '博主名称',
    blog_sex: '博主性别',
    blog_country: '博主国家',
    language: '语言',
    fans: '粉丝量',
    home_link: '主页链接',
    authorized: '已授权',
    unauthorized: '未授权',
    confirm: '确认',
    cancel: '取消'
  },
  login: {
    slog1_before: 'WotoHub已经完成',
    slog1_after: '次红人合作',
    slog2_before: '累计推广了',
    slog2_after: '件产品',
    slog3_before: '帮助红人获得了',
    slog3_after: '元收入',
    sampling: '数据来源：WotoHub抽样数据，仅供参考',
    name: '海外红人智能营销云',
    tab_1: '用户登录',
    tab_2: '用户注册',
    tab_3: '忘记密码',
    Logout: '登出',
    login_slog1: '海量任务订单等你来领',
    login_slog2: '登录后即可和广告主沟通',
    email: '邮箱地址',
    password: '密码',
    password_rule: '8-20位',
    again_password: '再次输入密码',
    forgot_word1: '请输入您的邮箱地址,',
    forgot_word2: '并发送验证码',
    forgot_word3: '验证成功，请重新设置密码！',
    forgot_word4: '验证码错误，请输入正确的验证码',
    register_word1: '已有账号？去登录',
    next: '下一步',
    confirm: '确认',
    login_way1: 'Google账号登录',
    login_check_word: '同时授权我们查看您的Youtube频道信息',
    forgot: '忘记密码？',
    register: '注册账号',
    clause_word1: '若继续，则表示你同意我们的',
    clause_word2: '网站声明'
  },
  dialog: {
    note: '提示',
    authentication: '身份验证',
    startAuthor: '开始验证',
    notMe: '这不是我',
    authentication_word1: '该任务属于',
    authentication_word2: '的',
    authentication_word3: '我们会发送验证邮件到',
    authentication_word4: '请输入验证码帮助我们确定你的身份',
    authentication_word5:
      '不用担心，您可以授权我们社交媒体数据，让广告主看到您的频道 / 视频，从而获得更多合作机会！',
    verificationCode: '填写验证码',
    verificationCode_word1: '我们会发送验证邮件到',
    verificationCode_word2: '请在下方输入框中输入6位验证码',
    verificationCode_word3: '验证码',
    verificationCode_word4: '发送验证码',
    authorization_word1:
      '您还未授权给我们社交媒体平台的数据，广告主无法查看到您的频道 / 视频数据，授权后，广告主会根据您的社交媒体平台数据情况，决定是否要合作。',
    Authorization: '授权',
    Confirm: '确认',
    Cancel: '取消',
    Back: '返回',
    authorizeNow: '立即授权'
  },
  notify: {
    announcement: '公告',
    close: '关闭',
    message: '消息通知',
    view_all: '查看全部',
    marked_as_read: '一键已读',
    update_remind: '更新提醒',
    refresh: '刷新页面',
    notify_word_1:
      '平台刚刚进行了一些优化和更新，建议您刷新页面以便获得更好的体验。',
    all_message: '全部消息',
    all_read: '全部已读',
    unread: '未读',
    read: '已读'
  }
}
