<template>
  <el-container class="container">
    <!-- 菜单栏 -->
    <el-aside class="hidden-scrollbar" width="220px">
      <el-scrollbar>
        <aside-menu />
      </el-scrollbar>
    </el-aside>
    <!-- 主体 -->
    <el-container class="content-container">
      <!-- 主体头 -->
      <el-header>
        <main-header
          :pageName="
            $route.meta.title == 'TT Featured Products'
              ? 'TikTok Featured Products'
              : $route.meta.title
          "
        />
      </el-header>
      <!-- 主体内容 -->
      <el-main>
        <router-view :key="currentRouter" />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import mainHeader from "./components/header.vue";
import asideMenu from "./components/menu.vue";
export default {
  components: {
    mainHeader,
    asideMenu,
  },
  computed: {
    currentRouter() {
      return this.$route.path;
    },
  },
  created() {},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-width: 1440px;
  display: flex;
  background: #f5f4f9;

  .hidden-scrollbar {
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .el-aside {
    height: 100vh;
    background: linear-gradient(140deg, #a775ff 0%, #ff5277 100%);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 0px 30px 0px 0px;
    box-sizing: border-box;
    flex-shrink: 0;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
    .el-header {
      padding: 24px 32px;
      height: auto !important;
    }
    .el-main {
      flex: 1;
      padding: 16px;
      padding-top: 0;
    }
  }
}
</style>