import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "./styles/element-variables.scss";
import "normalize.css/normalize.css";
import "./styles/index.scss";
import "./styles/iconfont.css";
import "./styles/reset.scss";
import Plugin from "@/plugin";

import i18n from "@/assets/languages";

Vue.config.productionTip = false;

Vue.use(ElementUI);
Vue.use(Plugin);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
