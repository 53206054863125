export default {
  menu: {
    affiliate_center: 'Affiliate Center',
    my_distribution: 'My Distribution',
    task_center: 'Task Center',
    my_task: 'My Task',
    unaffirm_list: 'Pending',
    affirm_list: 'Confirmed',
    account_manage: 'Account Management',
    my_account: 'My Account'
  },
  place: {
    my_discount_code: 'Discount Code',
    income: 'Income',
    select_product: 'Select product',
    search: 'Search',
    create_time: 'Create Time',
    product: 'Product',
    distribution_links: 'Distribution Links',
    discount_code: 'Discount Code',
    commission_type: 'Commission Type',
    orders: 'Orders',
    operation: 'Operation',
    details: 'Details',
    more: 'More',
    delete: 'Delete',
    number_of_products: 'Number of products',
    number_of_orders: 'Number of orders',
    order_amount: 'Order amount',
    commission: 'Commission',
    order_status: 'Order Status',
    payment_status: 'Payment Status',
    actual_payment: 'Actual Payment',
    distribution_status: 'Distribution Status'
  },
  task_center: {
    slog: 'Check the task requirements, then you can apply for a collaboration',
    select: 'Select',
    target_country: 'Target Country',
    free_product: 'Free Product',
    prodect_value: 'Product Value',
    budget: 'Budget',
    app_promotion: 'APP Promotion',
    web_promotion: 'Web Promotion',
    applied_number: ' talents have applied'
  },
  list: {
    release_time: 'Publish date',
    contact_name: 'Contact person',
    area: 'Area',
    price_min: 'Min budget',
    price_max: 'Max budget',
    is_pass: 'If expired',
    is_accept: 'If accepted',
    start_date: 'From',
    end_date: 'To',
    true: 'Yes',
    false: 'No',
    input: 'Please enter',
    search: 'Research',
    reset: 'Reset',
    agree: 'Accept',
    disagree: 'Refuse',
    more: 'More',
    validity: 'Expiration date',
    task_intro: 'Task description',
    task_type: 'Task style',
    blog_type: 'Influencer style',
    blog_num: 'Influencer number',
    blog_fans: 'Number of Followers'
  },
  task_detail: {
    task_name: 'Task name',
    create_time: 'Create date',
    issuer: 'Publisher',
    confirm: 'confirm',
    cancel: 'cancel',
    tip: 'Note',
    cancel_tip:
      'You will be restricted to accept new task if you cancel too much times',
    confirmation: 'Confirmation',
    cancel_2: 'Cancel',
    accept: 'Accept',
    reject: 'Reject',
    reward: 'Budget',
    stage: 'Progress',
    linkup: 'In communication',
    feat: 'In collaboration',
    online: 'Published',
    finish: 'Completed',
    basic_info: 'Basic information',
    blog_type: 'Influencer style required',
    blog_num: 'Influencer number required',
    pay_reward: 'Your budget',
    blog_video: 'Video required',
    explain: 'Specification',
    pro_info: 'Product',
    pro_name: 'Product name',
    pro_link: 'Product link',
    task_link: 'Task link',
    platform: 'Platform',
    classify: 'Style',
    video_link: 'Your video link',
    operation: 'Operation',
    submit: 'Submit',
    shipping_address: 'Shipping address',
    express: 'Express',
    consignee: 'Consignee',
    contact_number: 'Contact number',
    mailing_address: 'Shipping address',
    save: 'Save',
    logistics_info: 'Logistics info',
    express_company: 'Express company',
    tracking_number: 'Tracking number',
    logistics_track: 'Logistics info',
    check_details: 'Check details',
    collection_Info: 'Collection Info',
    payment: 'Payment',
    payee: "Beneficiary's name",
    bank: "Beneficiary's bank name",
    payee_account: "Beneficiary's account number",
    evaluation: 'Evaluation',
    service_level: 'Please evaluate this service?',
    excellent: 'Excellent',
    great: 'Great',
    good: 'Good',
    chat: 'Chat',
    send: 'Send',
    cancel_cooperation: 'Cancel Collaboration',
    is_cancel_cooperation: 'If cancel this collaboration',
    cancel_reason: 'Cancel reason',
    cancel_cooperation_tip: 'Are you sure you want to decline this task?',
    task_status1: "Accepted, waiting for advertiser's confirmation",
    task_status3: 'Both confirmed',
    task_status4: 'Cancelled by advertiser',
    get_more: 'Getting more details',
    paypal: 'Paypal',
    bank_transfer: 'Bank transfer',
    product: 'Product',
    video_type1: 'dedicated',
    video_type2: 'integrated',
    video_type3: 'other'
  },
  accout: {
    age: 'Age',
    man: 'Male',
    woman: 'Female',
    birth: 'Date of birth',
    area: 'Country/Area',
    edit: 'Edit',
    add: 'Add',
    authorization: 'Authorization',
    EVPI: 'Improve personal info',
    basicInfo: 'Basic information',
    upload: 'upload',
    name: 'Name',
    country: 'Country',
    ADtype: 'Acceptable ads style',
    game: 'Game',
    ARPG: 'Action & Adventure Game',
    ACG: 'Casual Game',
    ETC: 'Other Game',
    RPG: 'RPG Game',
    eBusiness: 'E-Commerce',
    art: 'Arts & Collectibles',
    car: 'Automotive',
    toys: 'Baby & Toys',
    beauty: 'Beauty & Personal Care',
    clothing: 'Clothing, Shoes & Jewelry',
    electronics: 'Electronics & Accessories',
    foot: 'Food & Kitchen',
    home: 'Home & Household',
    pet: 'Pet Supplies',
    sport: 'Sports',
    other: 'Others',
    music: 'Music',
    teach: 'Education',
    science: 'Science & Technology',
    rate: 'Rate',
    dedicatedVideo: 'Dedicated video',
    integratedVideo: 'Integrated video',
    prerollAd: 'Pre-roll ads',
    middleAd: 'Middle ads',
    endAd: 'End ads',
    receipt_information: 'Receipt Information',
    payment_method_no: 'Payment method No.',
    collection_name: 'Name',
    bank_name: 'Bank Name',
    card_number: 'Card Number',
    contact_type: 'Contact information (please enter the correct information)',
    email: 'E-mail',
    blog_name: 'Name',
    blog_sex: 'Gender',
    blog_country: 'Country',
    language: 'Language',
    fans: 'Subscribers',
    home_link: 'Channel link',
    authorized: 'Authorized',
    unauthorized: 'Unauthorized',
    confirm: 'confirm'
  },
  login: {
    slog1_before: 'WotoHub has facilitated',
    slog1_after: 'collaborations',
    slog2_before: 'A total of',
    slog2_after: 'products have been promoted',
    slog3_before: 'Promotion amount of cooperation $',
    slog3_after: '',
    sampling: 'Data source: WotoHub sampling data, for reference only.',
    name: 'Linking influencers and earning opportunities',
    tab_1: 'Log in',
    tab_2: 'Register',
    tab_3: 'Forgot',
    Logout: 'Log out',
    login_slog1: 'Massive tasks are waiting for you',
    login_slog2: 'please log in and communicate with advertisers.',
    email: 'Email',
    password: 'Password',
    password_rule: '8-20 positions',
    again_password: 'Enter the password again',
    forgot_word1: 'Please enter your email address',
    forgot_word2: 'and send the verification code.',
    forgot_word3: 'The verification is successful, please reset the password',
    forgot_word4:
      'The verification code is wrong, please enter the correct one.',
    register_word1: 'Already have an account? Go to login.',
    next: 'Next step',
    confirm: 'Confirm',
    login_way1: 'Log in via Google account',
    login_check_word: 'And authorize your Youtube channel data',
    forgot: 'Forgot password?',
    register: 'Register',
    clause_word1: 'Continue and agree to abide our ',
    clause_word2: 'website statement'
  },
  dialog: {
    note: 'Note',
    authentication: 'Identity verification',
    startAuthor: 'Start verification',
    notMe: 'this is not me',
    authentication_word1: 'This task belongs to',
    authentication_word2: 'of',
    authentication_word3: 'we will send a verification email to',
    authentication_word4:
      'Please enter the verification code to help us confirm your identity',
    authentication_word5:
      "Don't worry, you can authorize your social media data to allow advertisers to see your channel/video for more collaboration opportunities!",
    verificationCode: 'Verification code',
    verificationCode_word1: 'We have sent a verification email to',
    verificationCode_word2: 'please enter the 6-digit verification code below',
    verificationCode_word3: 'Code',
    verificationCode_word4: 'Send',
    authorization_word1:
      'If you have not authorized the data of your social media platform, advertisers cannot check your channel/video data. After authorization, advertisers will decide whether to work with you based on these data.',
    Authorization: 'Authorization',
    Confirm: 'Confirm',
    Cancel: 'Cancel',
    Back: 'Back',
    authorizeNow: 'Authorize now'
  },
  notify: {
    announcement: 'Announcement',
    close: 'Close',
    message: 'message',
    view_all: 'View All',
    marked_as_read: 'Marked as read',
    update_remind: 'Update to remind',
    refresh: 'Refresh',
    notify_word_1:
      'The platform has just been optimized and updated, it is recommended that you refresh the page for a better experience.',
    all_message: 'View All',
    all_read: 'Marked as read',
    unread: 'Unread',
    read: 'Read'
  }
}
