import Vue from "vue";
import Router from "vue-router";
import Layout from "@/layout";
Vue.use(Router);

const login = () => import("@/views/Login"); //登录
const solicitationTask = () =>
  import("@/views/TaskCenter/solicitationTask.vue");
const taskDetail = () => import("@/views/TaskCenter/taskDetail.vue");
const tiktokSelection = () => import("@/views/TaskCenter/tiktokSelection.vue");
const pending = () => import("@/views/MyTask/pending.vue");
const pendingDetail = () => import("@/views/MyTask/pendingDetail.vue");
const confirmed = () => import("@/views/MyTask/confirmed.vue");
const confirmedDetail = () => import("@/views/MyTask/confirmedDetail.vue");
const distribution = () => import("@/views/Distribution/index.vue"); //分销
const account = () => import("@/views/Account/index.vue");
const applyMiddle = () =>
  import("@/views/TaskCenter/components/applyMiddle.vue");

export const constantRoutes = [
  // 登录
  {
    path: "/",
    component: login,
  },
  // 登录
  {
    path: "/login",
    component: login,
  },
  // apply中间页
  {
    path: "/applyMiddle",
    component: applyMiddle,
  },
  // TASK CENTER
  {
    path: "",
    component: Layout,
    meta: {
      title: "Task Center",
    },
    children: [
      {
        path: "/solicitationTask",
        component: solicitationTask,
        meta: {
          activeMenu: "/solicitationTask",
          title: "Solicitation Task",
        },
      },
      {
        path: "/tiktokSelection",
        component: tiktokSelection,
        meta: {
          activeMenu: "/tiktokSelection",
          title: "TT Featured Products",
        },
      },
      // detail
      {
        path: "/taskDetail",
        component: taskDetail,
        meta: {
          activeMenu: "/solicitationTask",
          title: "Task Detail",
        },
      },
    ],
  },
  // My Task
  {
    path: "",
    component: Layout,
    meta: {
      title: "My Task",
    },
    children: [
      {
        path: "/pending",
        component: pending,
        meta: {
          activeMenu: "/pending",
          title: "Pending",
        },
      },
      {
        path: "/pendingDetail",
        component: pendingDetail,
        meta: {
          activeMenu: "/pendingDetail",
          title: "Pending Detail",
        },
      },
      {
        path: "/confirmed",
        component: confirmed,
        meta: {
          activeMenu: "/confirmed",
          title: "Confirmed",
        },
      },
      // detail
      {
        path: "/confirmedDetail",
        component: confirmedDetail,
        meta: {
          activeMenu: "/confirmed",
          title: "Confirmed Detail",
        },
      },
    ],
  },
  // 分销
  {
    path: "/distribution",
    component: Layout,
    children: [
      {
        path: "/distribution",
        component: distribution,
        meta: {
          title: "Distribution",
        },
      },
    ],
  },
  // Account
  {
    path: "/account",
    component: Layout,
    children: [
      {
        path: "/account",
        component: account,
        meta: {
          title: "Account",
        },
      },
    ],
  },
];

const createRouter = () =>
  new Router({
    routes: constantRoutes,
    mode: "history",
  });

const router = createRouter();

// 路由钩子
router.beforeEach((to, from, next) => {
  next();
});

const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

export default router;
