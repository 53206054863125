const getDefaultState = () => {
  return {
    user_info: localStorage.getItem("user_info")
      ? JSON.parse(localStorage.getItem("user_info"))
      : "",
  };
};
const state = getDefaultState();

const mutations = {
  // 设置用户信息
  SET_USER_INFO: (state, user_info) => {
    localStorage.setItem("user_info", JSON.stringify(user_info));
    state.user_info = user_info;
  },
};
const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
