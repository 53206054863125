<template>
  <div class="sider hidden-scrollbar">
    <div class="sider-logo">
      <img src="@/assets/image/menu/logo.png" alt="" />
    </div>
    <div class="sider-item" v-for="item in menus" :key="item.path">
      <el-menu
        :default-active="activeMenu"
        text-color="#ffffff"
        active-text-color="#ffffff"
        @select="handleChange"
      >
        <el-submenu v-if="item.hasTwo" :index="item.path">
          <template slot="title">
            <span
              ><i class="iconfont" :class="item.meta.icon"></i
              >{{ item.meta.title }}</span
            >
          </template>
          <el-menu-item
            v-for="(item1, index1) in item.children"
            :key="index1"
            :index="item1.path"
            class="child-item"
            >{{ item1.meta.title }}</el-menu-item
          >
        </el-submenu>
        <el-menu-item v-else class="menu-item" :index="item.path">
          <i class="iconfont" :class="item.children[0].meta.icon"></i
          >{{ item.children[0].meta.title }}</el-menu-item
        >
      </el-menu>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      menus: [
        // TASK CENTER
        {
          path: "/solicitationTask",
          hasTwo: true,
          meta: {
            title: "Task Center",
            icon: "icon-taskcenter",
          },
          children: [
            {
              path: "/solicitationTask",
              meta: {
                activeMenu: "/solicitationTask",
                title: "Solicitation Task",
              },
            },
            {
              path: "/tiktokSelection",

              meta: {
                activeMenu: "/tiktokSelection",
                title: "TT Featured Products",
              },
            },
          ],
        },
        // My Task
        {
          path: "/pending",
          hasTwo: true,
          meta: {
            title: "My Task",
            icon: "icon-mytask",
          },
          children: [
            {
              path: "/pending",
              meta: {
                activeMenu: "/pending",
                title: "Pending",
              },
            },
            {
              path: "/confirmed",
              meta: {
                activeMenu: "/confirmed",
                title: "Confirmed",
              },
            },
          ],
        },
        // // 分销
        // {
        //   path: "/distribution",
        //   children: [
        //     {
        //       path: "/distribution",
        //       meta: {
        //         title: "Distribution",
        //          icon:'icon-fenxiao2'
        //       },
        //     },
        //   ],
        // },
        // Account
        {
          path: "/account",
          children: [
            {
              path: "/account",
              meta: {
                title: "Account",
                icon: "icon-account1",
              },
            },
          ],
        },
      ],
    };
  },
  computed: {
    // 激活菜单
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
  },
  created() {},
  methods: {
    handleChange(path) {
      this.$router.push(path);
    },
  },
};
</script>
  
<style lang="scss" scoped>
.sider {
  .sider-logo {
    width: 118px;
    height: 30px;
    padding-left: 24px;
    padding-top: 18px;
    margin-bottom: 50px;
    img {
      width: 118px;
      height: 30px;
    }
  }
  .sider-item {
    ::v-deep .el-menu {
      background-color: transparent;
      border-right: none;
    }
    ::v-deep .el-submenu__title i {
      color: #fff !important;
    }
    .menu-item,
    ::v-deep .el-submenu__title,
    .child-item {
      height: 48px;
      line-height: 48px;
      margin: 0 10px;
      border-radius: 12px;
      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
      &:focus {
        background-color: rgba(255, 255, 255, 0.2);
      }
      &:active {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
}
.el-menu-item.is-active {
  background-color: rgba(255, 255, 255, 0.2);
}
.iconfont {
  padding-right: 4px;
}
</style>